<template>
  <v-app>
    <template v-if="!isLoading">
      <v-snackbar :timeout="5000" v-model="copied" color="primary" rounded="pill" top>
        {{ $vuetify.lang.t('$vuetify.copy_clipboard') }}
        <template v-slot:action="{ attrs }">
          <v-btn color="black" text v-bind="attrs" @click="copied = false"> Close </v-btn>
        </template>
      </v-snackbar>
      <div class="dashboard-creators-web" :style="'height: ' + (screenWidth < 500 ? (!toggleOpen && filteredScores.length > 2 ? filteredScores.length * 330 + 180 : 1000) : 2292) + 'px !important'">
        <div class="bg-main"></div>
        <div class="bg-open"></div>
        <div class="content">
          <div class="header">
            <a href="https://www.twitch.tv" target="_blank"><img class="twitch-logo" src="/imgs/twitch-logo.png" /></a>
            <div class="creator-photo">
              <div class="photo">
                <v-tooltip bottom color="#9146ff" v-if="user !== null">
                  <template v-slot:activator="{ on, attrs }">
                    <v-avatar style="cursor: pointer" @click="logout" class="ml-2" v-bind="attrs" v-on="on">
                      <v-img :src="user?.profile_image_url"></v-img>
                    </v-avatar>
                  </template>
                  <span class="logout">{{ $vuetify.lang.t('$vuetify.logout') }}</span>
                </v-tooltip>
              </div>
            </div>
            <template v-if="user !== null">
              <div class="creator-username" v-text="user?.display_name"></div>
            </template>
          </div>
          <div class="welcome">{{ $vuetify.lang.t('$vuetify.heading_welcome') }}</div>
          <template v-if="openCampaign !== null && openCampaign.landing_page_enabled === true && pendingSignUp">
            <div class="sign-up" @click="signUp">{{ $vuetify.lang.t('$vuetify.sign_up_incomplete') }}</div>
          </template>
          <template v-if="toggleOpen">
            <div class="campaign">
              <div class="campaign-text">
                <div class="campaign-name-label" @click="toggle">
                  {{ openCampaign !== null ? campaignName : $vuetify.lang.t('$vuetify.open_campaigns') }}
                </div>
              </div>
              <template v-if="openCampaign !== null">
                <template v-if="(openCampaign.details_url !== null || openCampaign.landing_page_enabled === true) && score.rewarded">
                  <a class="details-link" @click="viewLanding(openCampaign)">
                    <div class="details-button">
                      <div class="button-label">{{ $vuetify.lang.t('$vuetify.campaign_details') }}</div>
                    </div>
                  </a>
                  <a class="details-link" :href="'/rewards/' + openCampaign.id">
                    <div class="rewards-button">
                      <v-badge class="rewards-badge" color="red" :content="score.coupons.length">
                        <div class="button-label">{{ $vuetify.lang.t('$vuetify.campaign_rewards') }}</div>
                      </v-badge>
                    </div>
                  </a>
                </template>
                <template v-else>
                  <template v-if="openCampaign.details_url !== null || openCampaign.landing_page_enabled === true">
                    <a class="details-link" @click="viewLanding(openCampaign)">
                      <div :class="screenWidth < 500 ? 'details-button' : 'rewards-button'">
                        <div class="button-label">{{ $vuetify.lang.t('$vuetify.campaign_details') }}</div>
                      </div>
                    </a>
                  </template>
                  <template v-if="score.rewarded">
                    <a class="details-link" :href="'/rewards/' + openCampaign.id">
                      <div :class="screenWidth < 500 ? 'details-button' : 'rewards-button'">
                        <v-badge class="rewards-badge" color="red" :content="score.coupons.length">
                          <div class="button-label">{{ $vuetify.lang.t('$vuetify.campaign_rewards') }}</div>
                        </v-badge>
                      </div>
                    </a>
                  </template>
                </template>
                <div class="campaign-details">
                  <div class="campaign-details-box"></div>
                  <div class="campaign-details-content">
                    <template v-if="!campaignStarted">
                      <div class="_2-current-score">
                        <div class="rectangle-6"></div>
                        <div class="starting-soon">{{ $vuetify.lang.t('$vuetify.starting_soon') }}</div>
                      </div>
                    </template>
                    <div class="start-date-label" :style="'top: ' + (campaignStarted ? 598 : 667) + 'px !important'">{{ $vuetify.lang.t('$vuetify.campaign_start_date') }}</div>
                    <div class="start-date-value" :style="'top: ' + (campaignStarted ? 598 : 667) + 'px !important'">
                      {{ new Date(openCampaign.start_date).toLocaleDateString() }}
                      {{ new Date(openCampaign.start_date).toLocaleTimeString() }}
                    </div>
                    <div class="end-date-label" :style="'top: ' + (campaignStarted ? 667 : 736) + 'px !important'">{{ $vuetify.lang.t('$vuetify.campaign_end_date') }}</div>
                    <div class="end-date-value" :style="'top: ' + (campaignStarted ? 667 : 736) + 'px !important'">
                      {{ new Date(openCampaign.end_date).toLocaleDateString() }}
                      {{ new Date(openCampaign.end_date).toLocaleTimeString() }}
                    </div>
                    <template v-if="campaignStarted">
                      <div class="time-left">{{ $vuetify.lang.t('$vuetify.campaign_time_left') }}</div>
                      <div class="time-left-value">
                        <count-down :currentTime="new Date().getTime()" :startTime="new Date().getTime()" :endTime="new Date(openCampaign.end_date).getTime()" :tipText="''" :tipTextEnd="''" :endText="''" :dayTxt="' days '" :hourTxt="' hours '" :minutesTxt="' mins '" :secondsTxt="' secs '">
                        </count-down>
                      </div>
                    </template>
                    <div class="includes-text">{{ $vuetify.lang.t('$vuetify.campaign_includes') }}</div>
                    <div class="includes-subs" v-if="openCampaign.include_subs">
                      <div class="includes-label">Subs</div>
                    </div>
                    <div class="includes-bits" v-if="openCampaign.include_bits">
                      <div class="includes-label">Bits</div>
                    </div>
                    <div class="includes-raids" v-if="openCampaign.include_raids">
                      <div class="includes-label">Raids</div>
                    </div>
                  </div>
                </div>
                <div class="campaign-details-mobile">
                  <div class="campaign-details-mobile-box"></div>
                  <div class="campaign-details-mobile-content">
                    <template v-if="!campaignStarted">
                      <div class="_2-current-score">
                        <div class="rectangle-6"></div>
                        <div class="starting-soon">{{ $vuetify.lang.t('$vuetify.starting_soon') }}</div>
                      </div>
                    </template>
                    <div class="campaign-details-mobile-start-date-label" :style="'top: ' + (campaignStarted ? 286 : 344) + 'px !important'">{{ $vuetify.lang.t('$vuetify.campaign_start_date') }}</div>
                    <div class="campaign-details-mobile-start-date-value" :style="'top: ' + (campaignStarted ? 286 : 344) + 'px !important'">
                      {{ new Date(openCampaign.start_date).toLocaleDateString() }}
                      {{ new Date(openCampaign.start_date).toLocaleTimeString() }}
                    </div>
                    <div class="campaign-details-mobile-end-date-label" :style="'top: ' + (campaignStarted ? 344 : 402) + 'px !important'">{{ $vuetify.lang.t('$vuetify.campaign_end_date') }}</div>
                    <div class="campaign-details-mobile-end-date-value" :style="'top: ' + (campaignStarted ? 344 : 402) + 'px !important'">
                      {{ new Date(openCampaign.end_date).toLocaleDateString() }}
                      {{ new Date(openCampaign.end_date).toLocaleTimeString() }}
                    </div>
                    <template v-if="campaignStarted">
                      <div class="campaign-details-mobile-time-left-label">{{ $vuetify.lang.t('$vuetify.campaign_time_left') }}</div>
                      <div class="campaign-details-mobile-time-left-value">
                        <count-down :currentTime="new Date().getTime()" :startTime="new Date().getTime()" :endTime="new Date(openCampaign.end_date).getTime()" :tipText="''" :tipTextEnd="''" :endText="''" :dayTxt="' days '" :hourTxt="' hours '" :minutesTxt="' mins '" :secondsTxt="' secs '">
                        </count-down>
                      </div>
                    </template>
                    <div class="campaign-details-mobile-includes-label">{{ $vuetify.lang.t('$vuetify.campaign_includes') }}</div>
                    <div class="campaign-details-mobile-includes-subs" v-if="openCampaign.include_subs">
                      <div class="campaign-details-mobile-includes-text">Subs</div>
                    </div>
                    <div class="campaign-details-mobile-includes-bits" v-if="openCampaign.include_bits">
                      <div class="campaign-details-mobile-includes-text">Bits</div>
                    </div>
                    <div class="campaign-details-mobile-includes-raids" v-if="openCampaign.include_raids">
                      <div class="campaign-details-mobile-includes-text">Raids</div>
                    </div>
                  </div>
                </div>
                <div class="current-score">
                  <template v-if="score.overlay_used">
                    <div class="current-score-box"></div>
                    <div class="current-score-label">{{ $vuetify.lang.t('$vuetify.current_score') }}</div>
                    <div class="current-score-value">{{ score.score }}</div>
                  </template>
                  <template v-else>
                    <div class="current-overlay-box"></div>
                    <div class="current-overlay-label">⚠️&nbsp;{{ $vuetify.lang.t('$vuetify.dashboard_unused_overlay') }}</div>
                  </template>
                </div>
                <div class="scores">
                  <div class="completed-lvl">
                    <div class="completed-lvl-box"></div>
                  </div>
                  <div class="completed-level-box"></div>
                  <div class="completed-level">{{ $vuetify.lang.t('$vuetify.completed_level') }}</div>
                  <template v-if="score.overlay_used">
                    <div class="completed-level-value">{{ getCompletedTier(score.score, score.campaign.tiers) }}</div>
                  </template>
                  <template v-else>
                    <div class="completed-level-value">N/A</div>
                  </template>
                  <div class="next-lvl-score">
                    <div class="next-lvl-box"></div>
                  </div>
                  <div class="next-level-score-box"></div>
                  <div class="next-level-score">{{ $vuetify.lang.t('$vuetify.next_level_score') }}</div>
                  <template v-if="score.overlay_used">
                    <div class="next-level-score-value">{{ getTargetScore(score.score, score.campaign.tiers) }}</div>
                  </template>
                  <template v-else>
                    <div class="next-level-score-value">N/A</div>
                  </template>
                </div>
                <div class="scores-mobile">
                  <div class="scores-mobile-completed">
                    <div class="scores-mobile-complete-level">
                      <div class="scores-mobile-complete-box"></div>
                    </div>
                    <div class="scores-mobile-completed-level">{{ $vuetify.lang.t('$vuetify.completed_level') }}</div>
                    <template v-if="score.overlay_used">
                      <div class="scores-mobile-completed-value">{{ getCompletedTier(score.score, score.campaign.tiers) }}</div>
                    </template>
                    <template v-else>
                      <div class="scores-mobile-completed-value">N/A</div>
                    </template>
                  </div>
                  <div class="scores-mobile-next">
                    <div class="scores-mobile-next-lvl">
                      <div class="scores-mobile-next-box"></div>
                    </div>
                    <div class="scores-mobile-next-level">{{ $vuetify.lang.t('$vuetify.next_level_score') }}</div>
                    <template v-if="score.overlay_used">
                      <div class="scores-mobile-next-value">{{ getTargetScore(score.score, score.campaign.tiers) }}</div>
                    </template>
                    <template v-else>
                      <div class="scores-mobile-next-value">N/A</div>
                    </template>
                  </div>
                </div>
                <div class="url">
                  <template v-if="isValidLink(openCampaign.link1_name, openCampaign.link1_url)">
                    <a class="details-link" :href="openCampaign.link1_url" target="_blank">
                      <div class="link1-button">
                        <template v-if="openCampaign.link1_name.length > 18">
                          <v-tooltip bottom color="#9146ff">
                            <template v-slot:activator="{ on, attrs }">
                              <div class="text-truncate link-label" style="max-width: 184px" v-bind="attrs" v-on="on">{{ openCampaign.link1_name }}</div>
                            </template>
                            <span class="link-text">{{ openCampaign.link1_name }}</span>
                          </v-tooltip>
                        </template>
                        <template v-else>
                          <div class="text-truncate link-label" style="max-width: 184px">{{ openCampaign.link1_name }}</div>
                        </template>
                      </div>
                    </a>
                  </template>
                  <template v-if="isValidLink(openCampaign.link2_name, openCampaign.link2_url)">
                    <a class="details-link" :href="openCampaign.link2_url" target="_blank">
                      <div class="link2-button">
                        <template v-if="openCampaign.link2_name.length > 18">
                          <v-tooltip bottom color="#9146ff">
                            <template v-slot:activator="{ on, attrs }">
                              <div class="text-truncate link-label" style="max-width: 184px" v-bind="attrs" v-on="on">{{ openCampaign.link2_name }}</div>
                            </template>
                            <span class="link-text">{{ openCampaign.link2_name }}</span>
                          </v-tooltip>
                        </template>
                        <template v-else>
                          <div class="text-truncate link-label" style="max-width: 184px">{{ openCampaign.link2_name }}</div>
                        </template>
                      </div>
                    </a>
                  </template>
                  <template v-if="isValidLink(openCampaign.link3_name, openCampaign.link3_url)">
                    <a class="details-link" :href="openCampaign.link3_url" target="_blank">
                      <div class="link3-button">
                        <template v-if="openCampaign.link3_name.length > 18">
                          <v-tooltip bottom color="#9146ff">
                            <template v-slot:activator="{ on, attrs }">
                              <div class="text-truncate link-label" style="max-width: 184px" v-bind="attrs" v-on="on">{{ openCampaign.link3_name }}</div>
                            </template>
                            <span class="link-text">{{ openCampaign.link3_name }}</span>
                          </v-tooltip>
                        </template>
                        <template v-else>
                          <div class="text-truncate link-label" style="max-width: 184px">{{ openCampaign.link3_name }}</div>
                        </template>
                      </div>
                    </a>
                  </template>
                  <div class="overlay">{{ $vuetify.lang.t('$vuetify.browser_source_url') }}</div>
                  <div class="overlay-tooltip">
                    <div class="overlay-box"></div>
                    <v-tooltip color="#9146ff" bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on" class="overlay-question">?</div>
                      </template>
                      <span class="logout">{{ $vuetify.lang.t('$vuetify.browser_source_help') }}</span>
                    </v-tooltip>
                  </div>
                  <div class="dark">
                    <div class="theme-dark" @click="copyToClipboard(0)">
                      <div class="theme-label">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'Dark' }}</div>
                      <svg class="copy2" width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3 12C3 10.3431 4.34315 9 6 9V24H21C21 25.6569 19.6569 27 18 27H6C4.34315 27 3 25.6569 3 24L3 12Z" fill="black" />
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9 6C9 4.34315 10.3431 3 12 3L24 3C25.6569 3 27 4.34315 27 6V18C27 19.6569 25.6569 21 24 21H12C10.3431 21 9 19.6569 9 18L9 6ZM12 18V6L24 6V18H12Z" fill="black" />
                      </svg>
                    </div>
                  </div>
                  <div class="light">
                    <div class="theme-light" @click="copyToClipboard(1)">
                      <div class="theme-label">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ 'Light' }}</div>
                      <svg class="copy" width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.92969 12C3.92969 10.3431 5.27283 9 6.92969 9V24H21.9297C21.9297 25.6569 20.5865 27 18.9297 27H6.92969C5.27283 27 3.92969 25.6569 3.92969 24L3.92969 12Z" fill="black" />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9.92969 6C9.92969 4.34315 11.2728 3 12.9297 3L24.9297 3C26.5865 3 27.9297 4.34315 27.9297 6V18C27.9297 19.6569 26.5865 21 24.9297 21H12.9297C11.2728 21 9.92969 19.6569 9.92969 18L9.92969 6ZM12.9297 18V6L24.9297 6V18H12.9297Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="url-mobile">
                  <div class="url2">
                    <div class="dark-mobile">
                      <div class="buttons-mobile" @click="copyToClipboard(0)">
                        <div class="tab-name-mobile">{{ 'Dark' }}</div>
                      </div>
                      <svg class="copy-mobile" width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.10596 4.17389C1.10596 3.59759 1.60108 3.13041 2.21184 3.13041V8.34781H7.74125C7.74125 8.9241 7.24613 9.39128 6.63537 9.39128H2.21184C1.60108 9.39128 1.10596 8.9241 1.10596 8.34781L1.10596 4.17389Z" fill="black" />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M3.31772 2.08694C3.31772 1.51064 3.81284 1.04346 4.4236 1.04346L8.84713 1.04346C9.4579 1.04346 9.95302 1.51064 9.95302 2.08694V6.26085C9.95302 6.83715 9.45789 7.30433 8.84713 7.30433H4.4236C3.81284 7.30433 3.31772 6.83715 3.31772 6.26085L3.31772 2.08694ZM4.4236 6.26085V2.08694L8.84713 2.08694V6.26085H4.4236Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                    <div class="dark-mobile">
                      <div class="buttons2-mobile" @click="copyToClipboard(1)">
                        <div class="tab-name-mobile">{{ 'Light' }}</div>
                      </div>
                      <svg class="copy2-mobile" width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.10596 4.17389C1.10596 3.59759 1.60108 3.13041 2.21184 3.13041V8.34781H7.74125C7.74125 8.9241 7.24613 9.39128 6.63537 9.39128H2.21184C1.60108 9.39128 1.10596 8.9241 1.10596 8.34781L1.10596 4.17389Z" fill="black" />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M3.31772 2.08694C3.31772 1.51064 3.81284 1.04346 4.4236 1.04346L8.84713 1.04346C9.4579 1.04346 9.95302 1.51064 9.95302 2.08694V6.26085C9.95302 6.83715 9.45789 7.30433 8.84713 7.30433H4.4236C3.81284 7.30433 3.31772 6.83715 3.31772 6.26085L3.31772 2.08694ZM4.4236 6.26085V2.08694L8.84713 2.08694V6.26085H4.4236Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                    <div class="overlay-mobile">{{ $vuetify.lang.t('$vuetify.browser_source_url') }}</div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="open-no-data">{{ $vuetify.lang.t('$vuetify.no_campaigns_available') }}</div>
              </template>
            </div>
          </template>
          <template v-else>
            <div class="open-campaigns">
              <div class="open-bg"></div>
              <div class="open-label" @click="toggle">{{ $vuetify.lang.t('$vuetify.open_campaigns') }}</div>
            </div>
          </template>
          <template v-if="toggleOpen">
            <div class="bg-closed"></div>
            <div class="bg-closed-mobile"></div>
            <div class="bg-closed-mobile-box"></div>
            <div class="closed">
              <div class="closed-title" @click="toggle">{{ $vuetify.lang.t('$vuetify.closed_campaigns') }}</div>
              <template v-if="closedCampaigns.length > 0">
                <template v-for="(year, index) in closedCampaignYears" :value="year">
                  <div :class="'closed-year-' + index" :key="year" @click="filterByYear(year)">
                    <div class="closed-year-value">{{ year }}</div>
                  </div>
                </template>
              </template>
            </div>
          </template>
          <template v-else>
            <div class="bg-closed-open" :style="'height: ' + (screenWidth < 500 ? (!toggleOpen && filteredScores.length > 2 ? filteredScores.length * 330 - 200 : 900) : 1200) + 'px !important'"></div>
            <div class="close-cam">
              <div class="closed-open-title" @click="toggle">{{ $vuetify.lang.t('$vuetify.closed_campaigns') }}</div>
              <template v-if="closedCampaigns.length > 0">
                <div class="closed-select">
                  <div class="closed-select-year">
                    <v-select v-model="year" :items="campaignYears" solo height="64"></v-select>
                  </div>
                </div>
              </template>
            </div>
            <template v-if="filteredScores.length > 0">
              <div class="closed-table">
                <div>
                  <div class="campaign-name">{{ $vuetify.lang.t('$vuetify.campaign_name') }}</div>
                  <div class="level-completed" v-html="$vuetify.lang.t('$vuetify.campaign_level')"></div>
                  <div class="score">{{ $vuetify.lang.t('$vuetify.campaign_score') }}</div>
                  <div class="includes">{{ $vuetify.lang.t('$vuetify.campaign_includes') }}</div>
                  <div class="star-date">{{ $vuetify.lang.t('$vuetify.campaign_start_date') }}</div>
                  <div class="end-date">{{ $vuetify.lang.t('$vuetify.campaign_end_date') }}</div>
                </div>
                <template v-for="(closedScore, index) in filteredScores">
                  <div :key="index">
                    <div :class="'row-bg-' + index"></div>
                    <template v-if="closedScore.campaign.details_url !== null">
                      <a class="details-link" :href="closedScore.campaign.details_url" target="_blank">
                        <div :class="'row-rewards-bg-' + index">
                          <div class="row-button-label">{{ $vuetify.lang.t('$vuetify.campaign_details') }}</div>
                        </div>
                      </a>
                    </template>
                    <template v-else>
                      <div :class="'row-rewards-bg-' + index" style="background-color: #353535">
                        <div class="row-button-label" style="color: #222222" v-html="$vuetify.lang.t('$vuetify.campaign_no_details')"></div>
                      </div>
                    </template>
                    <template v-if="closedScore.rewarded">
                      <a class="details-link" :href="'/rewards/' + closedScore.campaign.id">
                        <div :class="'row-details-bg-' + index">
                          <div class="row-button-label">{{ $vuetify.lang.t('$vuetify.campaign_rewards') }}</div>
                        </div>
                      </a>
                    </template>
                    <template v-else>
                      <div :class="'row-details-bg-' + index" style="background-color: #353535">
                        <div class="row-button-label" style="color: #222222" v-html="$vuetify.lang.t('$vuetify.campaign_no_rewards')"></div>
                      </div>
                    </template>
                    <template v-if="closedScore.campaign.name.length > 14">
                      <v-tooltip bottom color="#9146ff">
                        <template v-slot:activator="{ on, attrs }">
                          <div :class="'text-truncate row-name-' + index" style="max-width: 190px" v-bind="attrs" v-on="on">{{ closedScore.campaign.name }}</div>
                        </template>
                        <span class="logout">{{ closedScore.campaign.name }}</span>
                      </v-tooltip>
                    </template>
                    <template v-else>
                      <div :class="'text-truncate row-name-' + index" style="max-width: 190px">{{ closedScore.campaign.name }}</div>
                    </template>
                    <template v-if="closedScore.overlay_used">
                      <div :class="'row-level-' + index">{{ getCompletedTier(closedScore.score, closedScore.campaign.tiers) }}</div>
                      <div :class="'row-score-' + index">{{ closedScore.score }}</div>
                    </template>
                    <template v-else>
                      <div :class="'row-level-' + index">N/A</div>
                      <div :class="'row-score-' + index">N/A</div>
                    </template>
                    <div :class="'row-includes-' + index">{{ closedScore.campaign.include_subs ? 'Subs' : '' }} {{ closedScore.campaign.include_bits ? 'Bits' : '' }} {{ closedScore.campaign.include_raids ? 'Raids' : '' }}</div>
                    <div :class="'row-start-' + index">{{ new Date(closedScore.campaign.start_date).toLocaleDateString() }}</div>
                    <div :class="'row-end-' + index">{{ new Date(closedScore.campaign.end_date).toLocaleDateString() }}</div>
                  </div>
                </template>
              </div>
              <div class="closed-table-mobile">
                <template v-for="(closedScore, index) in filteredScores">
                  <div class="row-mobile" :key="index" :style="'top: ' + index * 240 + 'px !important'">
                    <div class="row-bg-mobile"></div>
                    <template v-if="closedScore.campaign.details_url !== null">
                      <a class="details-link" :href="closedScore.campaign.details_url" target="_blank">
                        <div class="buttons">
                          <div class="tab-name">{{ $vuetify.lang.t('$vuetify.campaign_details') }}</div>
                        </div>
                      </a>
                    </template>
                    <template v-else>
                      <div class="buttons" style="background-color: #353535">
                        <div class="tab-name" style="color: #222222" v-html="$vuetify.lang.t('$vuetify.campaign_no_details')"></div>
                      </div>
                    </template>
                    <template v-if="closedScore.rewarded">
                      <a class="details-link" :href="'/rewards/' + closedScore.campaign.id">
                        <div class="buttons2">
                          <div class="tab-name">{{ $vuetify.lang.t('$vuetify.campaign_rewards') }}</div>
                        </div>
                      </a>
                    </template>
                    <template v-else>
                      <div class="buttons2" style="background-color: #353535">
                        <div class="tab-name" style="color: #222222" v-html="$vuetify.lang.t('$vuetify.campaign_no_rewards')"></div>
                      </div>
                    </template>
                    <div class="group-65">
                      <div class="campaign-name-mobile">{{ $vuetify.lang.t('$vuetify.campaign_name') }}</div>
                      <div class="level-completed-mobile">{{ $vuetify.lang.t('$vuetify.campaign_level_completed') }}</div>
                      <div class="score-mobile">{{ $vuetify.lang.t('$vuetify.campaign_score') }}</div>
                      <div class="includes-mobile">{{ $vuetify.lang.t('$vuetify.campaign_includes') }}</div>
                      <div class="start-date-mobile">{{ $vuetify.lang.t('$vuetify.campaign_start_date') }}</div>
                      <div class="end-date-mobile">{{ $vuetify.lang.t('$vuetify.campaign_end_date') }}</div>
                      <div class="prod-8929">{{ closedScore.campaign.name }}</div>
                      <template v-if="closedScore.overlay_used">
                        <div class="_12">{{ getCompletedTier(closedScore.score, closedScore.campaign.tiers) }}</div>
                        <div class="_100-000-000">{{ closedScore.score }}</div>
                      </template>
                      <template v-else>
                        <div class="_12">N/A</div>
                        <div class="_100-000-000">N/A</div>
                      </template>
                      <div class="subs-bits-mobile">{{ closedScore.campaign.include_subs ? 'Subs' : '' }} {{ closedScore.campaign.include_bits ? 'Bits' : '' }} {{ closedScore.campaign.include_raids ? 'Raids' : '' }}</div>
                      <div class="_2022-11-30">{{ new Date(closedScore.campaign.start_date).toLocaleDateString() }}</div>
                      <div class="_2022-12-10">{{ new Date(closedScore.campaign.end_date).toLocaleDateString() }}</div>
                    </div>
                  </div>
                </template>
              </div>
            </template>
            <template v-else>
              <div class="closed-no-data">{{ $vuetify.lang.t('$vuetify.no_campaigns_available') }}</div>
            </template>
          </template>
          <div>
            <img class="live-pill" src="/imgs/live-pill.png" />
            <img class="purple-bits" src="/imgs/purple-bits.png" />
          </div>
          <div class="footer">
            <a href="https://www.twitch.tv" target="_blank"><img class="twitch-glitch-white" src="/imgs/twitch-glitch-white.png" /></a>
            <div>
              <a class="terms-of-service" href="https://www.twitch.tv/p/en/legal/terms-of-service/" target="_blank">{{ $vuetify.lang.t('$vuetify.terms') }}</a>
            </div>
            <div class="partner">{{ $vuetify.lang.t('$vuetify.develop_by') }}</div>
            <div>
              <a class="privacy-policy" href="https://www.twitch.tv/p/en/legal/privacy-notice/" target="_blank">{{ $vuetify.lang.t('$vuetify.privacy_policy') }}</a>
            </div>
            <div class="trade-mark">© {{ new Date().getFullYear() }} Twitch Interactive Inc</div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <v-overlay>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </template>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CountDown from 'vue2-countdown'
import { sortBy, isEmpty } from 'lodash'

export default {
  name: 'Dashboard',
  components: {
    CountDown
  },
  data() {
    return {
      isLoading: false,
      score: null,
      openCampaign: null,
      closedCampaigns: [],
      toggleOpen: false,
      copied: false,
      year: Number(new Date().getFullYear()),
      screenHeight: 0,
      screenWidth: 0
    }
  },
  computed: {
    ...mapGetters(['user', 'scores']),
    campaignStarted() {
      return new Date() > new Date(this.openCampaign?.start_date)
    },
    campaignYears() {
      const years = []
      this.closedCampaigns.forEach((campaign) => {
        if (!years.includes(campaign.year)) {
          years.push(campaign.year)
        }
      })
      return sortBy(years, 'desc')
    },
    closedCampaignYears() {
      const years = this.closedCampaigns.length ? this.closedCampaigns.map((item) => item.year).filter((value, index, self) => self.indexOf(value) === index) : []
      return sortBy(years)
    },
    filteredScores() {
      return this.scores?.length ? this.scores.filter((item) => item.campaign.year === this.year && this.campaignExpired(item.campaign.end_date)) : []
    },
    pendingSignUp() {
      return this.campaignStarted && this.score.is_sign_up && !this.score.submitted
    },
    campaignName() {
      return !isEmpty(this.openCampaign?.local_name) ? this.openCampaign?.local_name : this.openCampaign?.name
    }
  },
  methods: {
    ...mapActions(['loadSystem', 'signOut']),
    toggle(scrollToTop) {
      this.toggleOpen = !this.toggleOpen
      if (scrollToTop) {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      }
    },
    logout() {
      this.signOut().then(() => {
        this.$router.push('/')
      })
    },
    getCompletedTier(score, tiers) {
      var tierIndex = -1
      for (let i = 0; i < tiers.length; i++) {
        if (tiers[i] <= score) {
          tierIndex = i
        }
      }
      if (tierIndex === -1) return 'N/A'
      return `${tierIndex + 1}`
    },
    getTargetScore(score, tiers) {
      for (let i = 0; i < tiers.length; i++) {
        if (score < tiers[i]) {
          return `${tiers[i]}`
        }
      }
      return 'N/A'
    },
    copyToClipboard(theme) {
      navigator.clipboard.writeText(this.browserSourceUrl(this.score, theme))
      this.copied = true
    },
    browserSourceUrl(score, theme) {
      return `${location.origin}/overlay/${score.browser_source_slug}/${this.user?.id}/${score?.campaign?.id}/${theme}`
    },
    campaignExpired(endDate) {
      return new Date() > new Date(endDate)
    },
    setData() {
      this.scores.forEach((score) => {
        if (!this.campaignExpired(score.campaign.end_date)) {
          this.score = score
          this.openCampaign = score.campaign
        } else {
          this.closedCampaigns.push(score.campaign)
        }
      })
      if (this.openCampaign) {
        this.toggleOpen = true
      }
    },
    filterByYear(year) {
      this.year = year
      this.toggle(true)
    },
    onScreenResize() {
      window.addEventListener('resize', () => {
        this.updateScreenHeight()
        this.updateScreenWidth()
      })
    },
    updateScreenHeight() {
      this.screenHeight = window.innerHeight
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth
    },
    viewLanding(campaign) {
      const routeData = this.$router.resolve({ name: 'campaign', params: { slug: campaign.slug } })
      window.open(routeData.href, '_blank')
    },
    isValidLink(name, url) {
      return !isEmpty(name) && !isEmpty(url)
    },
    signUp() {
      sessionStorage.campaign = JSON.stringify(this.openCampaign)
      this.$router.push({ name: 'signUp' })
    }
  },
  mounted() {
    if (process.env.VUE_APP_NO_PROD_REDIRECT !== 'true') {
      location.replace('https://glitch-exp.com')
    }
    this.updateScreenHeight()
    this.updateScreenWidth()
    this.onScreenResize()
    this.$vuetify.lang.current = localStorage.language ?? 'en'
    if (this.user == null || this.scores == null) {
      this.isLoading = true
      this.loadSystem().then((_) => {
        this.setData()
        this.isLoading = false
      })
    } else {
      this.setData()
    }
  }
}
</script>

<style>
body {
  background: #121212 !important;
}
</style>

<style lang="scss" scoped>
.dashboard-creators-web,
.dashboard-creators-web * {
  box-sizing: border-box;
}
.dashboard-creators-web {
  background: #000000 !important;
  width: 100% !important;
  min-width: 1920px;
  height: 2292px;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 500px) {
    min-width: 393px !important;
  }
}
.bg-main {
  background: linear-gradient(180deg, #e3ff93 0%, #c1ff0c 19.27%);
  width: 100%;
  height: 1074px;
  position: absolute;
  left: 0.46px;
  top: -3px;
  @media only screen and (max-width: 500px) {
    height: 1000px;
  }
}
.bg-open {
  background: #000000;
  border-radius: 100px 100px 0px 0px;
  width: 100%;
  height: 1103px;
  position: absolute;
  left: 0.46px;
  top: 305px;
  box-shadow: 0px -40px 44px 0px rgba(40, 48, 18, 0.25);
  @media only screen and (max-width: 500px) {
    background: #000000;
    border-radius: 30px 30px 0px 0px;
    width: 100% !important;
    height: 844px;
    position: absolute;
    left: 0px;
    top: 157px;
  }
}
.content {
  margin: auto;
  padding: 12px;
  min-width: 1420px;
  max-width: 1920px;

  @media only screen and (max-width: 500px) {
    max-width: 500px;
  }
}
.welcome {
  color: #000000;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 85px;
  line-height: 106px;
  position: absolute;
  left: calc(50% - 663.54px);
  top: calc(50% - 978.6px);

  @media only screen and (max-width: 500px) {
    color: #000000;
    text-align: left;
    font: 700 24px/90% 'RoobertRegular', sans-serif;
    position: absolute;
    left: 43px;
    top: 94px;
    width: 309px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.sign-up {
  color: red;
  text-align: left;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 27.35px;
  position: absolute;
  left: calc(50% - 663.54px);
  top: calc(50% - 665px);
  cursor: pointer;
  z-index: 10;

  @media only screen and (max-width: 500px) {
    text-align: left;
    font: 700 11px/27.35px 'RoobertRegular', sans-serif;
    position: absolute;
    left: 43px;
    top: 154px;
    width: 309px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.sign-up:hover {
  color: #7d5bbe;
}

.header {
  position: absolute;
  inset: 0;
}
.creator-photo {
  width: 42px;
  height: 42px;
  position: static;
  z-index: 1;
}
.photo {
  width: 42px;
  height: 42px;
  position: absolute;
  right: 70px;
  top: 60px;
  z-index: 1;

  @media only screen and (max-width: 500px) {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 44px;
    top: 44px;
    z-index: 1;
  }
}
.logout {
  color: var(--black, #000000);
  font: 600 20px/18px 'RoobertRegular';

  @media only screen and (max-width: 500px) {
    z-index: 1;
  }
}
.creator-username {
  color: var(--black, #000000);
  text-align: center;
  font: 600 20px/18px 'RoobertRegular';
  position: absolute;
  right: 130px;
  top: 63px;
  width: 372px;
  height: 41px;
  display: flex;
  align-items: center;
  justify-content: right;

  @media only screen and (max-width: 500px) {
    text-align: center;
    font: 600 14px/18px 'RoobertRegular', sans-serif;
    position: absolute;
    right: 70px;
    top: 64px;
    width: 124px;
    height: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.twitch-logo {
  width: 131px;
  height: 44px;
  position: absolute;
  left: 99.46px;
  top: 61px;
  z-index: 1;
  cursor: pointer;

  @media only screen and (max-width: 500px) {
    width: 54px;
    height: 18px;
    left: 18px;
  }
}
.campaign {
  position: absolute;
  inset: 0;
}
.scores-mobile {
  visibility: hidden;
  @media only screen and (max-width: 500px) {
    visibility: visible;
  }
}
.scores-mobile-completed {
  box-sizing: border-box;
  position: absolute;
  inset: 0;
}
.scores-mobile-complete-level {
  width: 307px;
  height: 40px;
  position: static;
}
.scores-mobile-complete-box {
  border-radius: 20px;
  border-style: solid;
  border-color: var(--color-brand-accent-lime, #beff00);
  border-width: 1px;
  width: 307px;
  height: 40px;
  position: absolute;
  left: 43px;
  top: 603px;
}
.scores-mobile-completed-level {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 11px/27.35px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 60px;
  top: 609px;
  width: 155px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.scores-mobile-completed-value {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 14px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 60px;
  top: 622px;
  width: 105px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.scores-mobile-next-lvl {
  width: 307px;
  height: 40px;
  position: static;
}
.scores-mobile-next-box {
  border-radius: 20px;
  border-style: solid;
  border-color: var(--color-brand-accent-lime, #beff00);
  border-width: 1px;
  width: 307px;
  height: 40px;
  position: absolute;
  left: 43px;
  top: 660px;
}
.scores-mobile-next-level {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 11px/27.35px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 60px;
  top: 666px;
  width: 166px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.scores-mobile-next-value {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 14px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 60px;
  top: 684px;
  width: 106px;
  height: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.scores {
  width: 1338px;
  height: 124px;
  position: static;

  @media only screen and (max-width: 500px) {
    visibility: hidden;
  }
}
.completed-lvl {
  width: 655px;
  height: 120px;
  position: static;
}
.completed-lvl-box {
  border-radius: 128px;
  border-style: solid;
  border-color: var(--color-brand-accent-lime, #beff00);
  border-width: 3px;
  width: 655px;
  height: 120px;
  position: absolute;
  left: calc(50% - 660px);
  top: 1105px;
}
.next-lvl-score {
  width: 644px;
  height: 120px;
  position: static;
}
.next-lvl-box {
  border-radius: 128px;
  border-style: solid;
  border-color: var(--color-brand-accent-lime, #beff00);
  border-width: 3px;
  width: 644px;
  height: 120px;
  position: absolute;
  left: calc(50% + 40px);
  top: 1109px;
}
.completed-level {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/27.35px 'RoobertRegular';
  position: absolute;
  left: calc(50% - 610px);
  top: 1136px;
  width: 306px;
}
.next-level-score {
  color: var(--color-brand-muted-smoke, #d2d2e6);
  text-align: left;
  font: 600 25px/27.35px 'RoobertRegular';
  position: absolute;
  left: calc(50% + 90px);
  top: 1131px;
  width: 357px;
}
.completed-level-value {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 30px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% - 610px);
  top: 1164px;
  width: 475px;
}
.next-level-score-value {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 30px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% + 90px);
  top: 1159px;
  width: 475px;
}
.completed-level-box {
  border-radius: 128px;
  border-style: solid;
  border-color: var(--color-brand-accent-lime, #beff00);
  border-width: 3px;
  width: 655px;
  height: 120px;
  position: absolute;
  left: calc(50% - 660px);
  top: 1107px;
}
.next-level-score-box {
  border-radius: 128px;
  border-style: solid;
  border-color: var(--color-brand-accent-lime, #beff00);
  border-width: 3px;
  width: 644px;
  height: 120px;
  position: absolute;
  left: calc(50% + 40px);
  top: 1107px;
}
.current-score {
  width: 1338px;
  height: 120px;
  position: static;
}
.current-score-box {
  background: #beff00;
  border-radius: 128px;
  width: 1338px;
  height: 120px;
  position: absolute;
  left: calc(50% - 660px);
  top: 945px;

  @media only screen and (max-width: 500px) {
    width: 307px;
    height: 40px;
    left: calc(50% - 171px);
    top: 545px;
  }
}
.current-overlay-box {
  background: red;
  border-radius: 128px;
  width: 1338px;
  height: 120px;
  position: absolute;
  left: calc(50% - 660px);
  top: 945px;

  @media only screen and (max-width: 500px) {
    width: 307px;
    height: 40px;
    left: calc(50% - 171px);
    top: 545px;
  }
}
.current-score-value {
  color: #000000;
  text-align: right;
  font: 600 50px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% + 280px);
  top: 990px;
  width: 344px;
  height: 43px;

  @media only screen and (max-width: 500px) {
    font: 600 14px/37.23px 'RoobertRegular', sans-serif;
    text-align: right;
    position: absolute;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    left: calc(50% - 35px);
    top: 542px;
  }
}
.current-score-label {
  color: #000000;
  text-align: left;
  font: 600 50px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% - 610px);
  top: 985px;
  width: 617px;
  height: 43px;

  @media only screen and (max-width: 500px) {
    font: 600 14px/37.23px 'RoobertRegular', sans-serif;
    left: calc(50% - 155px);
    top: 545px;
  }
}
.current-overlay-label {
  color: white;
  text-align: center;
  font: 500 40px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% - 610px);
  top: 985px;
  width: 1217px;
  height: 43px;

  @media only screen and (max-width: 500px) {
    font: 500 12px/37.23px 'RoobertRegular', sans-serif;
    left: calc(50% - 155px);
    top: 545px;
  }
}
.campaign-details-mobile {
  visibility: hidden;
  @media only screen and (max-width: 500px) {
    visibility: visible;
    box-sizing: border-box;
    position: absolute;
    inset: 0;
  }
}
.campaign-details-mobile-box {
  background: #111111;
  border-radius: 15px;
  width: 307px;
  height: 260px;
  position: absolute;
  left: 43px;
  top: 263px;
}
.campaign-details-mobile-content {
  width: 251px;
  height: 212px;
  position: static;
}
.campaign-details-mobile-start-date-label {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 14px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 71px;
  top: 286px;
  width: 110.62px;
}
.campaign-details-mobile-start-date-value {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 14px/100% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 219px;
  top: 296px;
  width: 102px;
}
.campaign-details-mobile-end-date-label {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 14px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 71px;
  top: 344px;
  width: 100.62px;
}
.campaign-details-mobile-end-date-value {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 14px/100% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 219px;
  top: 354px;
  width: 103px;
}
.campaign-details-mobile-time-left-label {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 14px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 71px;
  top: 402px;
  width: 120.62px;
}
.campaign-details-mobile-time-left-value {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 14px/100% 'RoobertRegular', sans-serif;
  position: absolute;
  left: 219px;
  top: 412px;
  width: 123px;
}
.campaign-details-mobile-includes-label {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 14px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 71px;
  top: 460px;
  width: 100.62px;
}
.campaign-details-mobile-includes-subs {
  border-radius: 286.39px;
  border-style: solid;
  border-color: #beff00;
  border-width: 1px;
  padding: 0.5px 10px 0.5px 10px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 23px;
  position: absolute;
  left: 217px;
  top: 467px;
}
.campaign-details-mobile-includes-bits {
  border-radius: 286.39px;
  border-style: solid;
  border-color: #beff00;
  border-width: 1px;
  padding: 0.5px 10px 0.5px 10px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 23px;
  position: absolute;
  left: 263px;
  top: 467px;
}
.campaign-details-mobile-includes-raids {
  border-radius: 286.39px;
  border-style: solid;
  border-color: #beff00;
  border-width: 1px;
  padding: 0.5px 10px 0.5px 10px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  width: 39px;
  height: 23px;
  position: absolute;
  right: 42px;
  top: 467px;
}
.campaign-details-mobile-includes-text {
  color: #beff00;
  text-align: center;
  font: 600 12px/37.23px 'RoobertRegular', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.campaign-details {
  width: 1335px;
  height: 361px;
  position: static;
  @media only screen and (max-width: 500px) {
    visibility: hidden;
  }
}
.campaign-details-box {
  background: #111111;
  border-radius: 56px;
  width: 1335px;
  height: 361px;
  position: absolute;
  left: calc(50% - 660px);
  top: 544px;
}
.campaign-details-content {
  width: 984px;
  height: 258px;
  position: static;
}
.start-date-label {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 30px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% - 460px);
  top: 598px;
  width: 251px;
}
.start-date-value {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 30px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% + 80px);
  top: 598px;
  width: 401px;
}
.end-date-label {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 30px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% - 460px);
  top: 667px;
  width: 251px;
}
.end-date-value {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 30px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% + 80px);
  top: 667px;
  width: 401px;
}
.time-left {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 30px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% - 460px);
  top: 736px;
  width: 251px;
}
.time-left-value {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 30px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% + 80px);
  top: 736px;
  width: 472px;
}
.includes-text {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 30px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% - 460px);
  top: 805px;
  width: 251px;
}
.includes-subs {
  border-radius: 286.39px;
  border-style: solid;
  border-color: #beff00;
  border-width: 2.5px;
  padding: 6px 20px 6px 20px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% + 80px);
  top: calc(50% - 340px);
}
.includes-label {
  color: #beff00;
  text-align: right;
  font: 600 30px/37.23px 'RoobertRegular';
  position: relative;
}
.includes-bits {
  border-radius: 286.39px;
  border-style: solid;
  border-color: #beff00;
  border-width: 2.5px;
  padding: 6px 20px 6px 20px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% + 205px);
  top: calc(50% - 340px);
}
.includes-raids {
  border-radius: 286.39px;
  border-style: solid;
  border-color: #beff00;
  border-width: 2.5px;
  padding: 6px 20px 6px 20px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% + 315px);
  top: calc(50% - 340px);
}
.campaign-text {
  width: 468px;
  height: 38px;
  position: static;

  @media only screen and (max-width: 500px) {
  }
}
.campaign-name-label {
  color: #f0f0ff;
  text-align: left;
  font: 700 60px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% - 661px);
  top: calc(50% - 724px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 10;
  cursor: pointer;

  @media only screen and (max-width: 500px) {
    color: #f0f0ff;
    text-align: left;
    font: 700 24px/37.23px 'RoobertRegular', sans-serif;
    position: absolute;
    left: calc(50% - 152px);
    top: calc(50% - 330px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.campaign-name-label:hover {
  color: #beff00;
}
.rewards-button {
  background: #beff00;
  border-radius: 286.39px;
  padding: 14px 20px 14px 20px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% - -520px);
  top: calc(50% - 738px);
  z-index: 1;
  width: 155px;
  cursor: pointer;

  span > :last-child > :first-child {
    width: 40px !important;
    margin-left: -16px;
    top: -20px !important;
    background-color: #e91916 !important;
    border-color: #e91916 !important;
  }

  @media only screen and (max-width: 500px) {
    padding: 1px 13px 1px 13px;
    display: flex;
    flex-direction: column;
    gap: 37.23px;
    align-items: center;
    justify-content: center;
    height: 30px;
    position: absolute;
    left: calc(50% - 53.5px);
    top: calc(50% - 280px);
    width: 86px;

    span > :last-child > :first-child {
      width: 34px !important;
      margin-left: -14px;
      top: -10px !important;
      background-color: #e91916 !important;
      border-color: #e91916 !important;
    }
  }
}
.rewards-button:hover {
  background: #7d5bbe;
}
.rewards-badge {
  color: var(--black, #000000);
  font: 600 30px/37.23px 'RoobertRegular';
}
.button-label {
  color: var(--black-ops, #000000);
  text-align: right;
  font: 600 28px/37.23px 'RoobertRegular';
  position: relative;

  @media only screen and (max-width: 500px) {
    font: 600 15px/37.23px 'RoobertRegular';
  }
}
.details-button {
  background: #beff00;
  border-radius: 286.39px;
  padding: 14px 20px 14px 20px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% - -350px);
  top: calc(50% - 738px);
  z-index: 1;
  width: 155px;
  cursor: pointer;

  @media only screen and (max-width: 500px) {
    padding: 1px 13px 1px 13px;
    display: flex;
    flex-direction: column;
    gap: 37.23px;
    align-items: center;
    justify-content: center;
    height: 30px;
    position: absolute;
    left: calc(50% - 151px);
    top: calc(50% - 280px);
    width: 86px;
  }
}
.details-button:hover {
  background: #7d5bbe;
}
.details-link {
  text-decoration: none;
}
.url {
  width: 542.54px;
  height: 49px;
  position: static;
  visibility: visible;
  @media only screen and (max-width: 500px) {
    visibility: hidden;
  }
}
.url-mobile {
  width: 542.54px;
  height: 49px;
  position: static;
  visibility: hidden;
  @media only screen and (max-width: 500px) {
    visibility: visible;
  }
}
.url2 {
  width: 185px;
  height: 38px;
  position: static;
}
.light {
  width: 129px;
  height: 48px;
  position: static;
}
.theme-light {
  background: #beff00;
  border-radius: 286.39px;
  padding: 5px 15px 5px 15px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% + 546px);
  top: 1310px;
  z-index: 1;
  cursor: pointer;
}
.theme-light:hover {
  background: #7d5bbe;
}
.theme-label {
  color: #000000;
  text-align: right;
  font: 500 30px/37.23px 'RoobertRegular';
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 100px;
}
.copy {
  position: absolute;
  left: calc(50% - 54px);
  top: 10px;
  overflow: visible;
}
.dark {
  width: 129px;
  height: 48px;
  position: static;
}
.dark-mobile {
  width: 45px;
  height: 17px;
  position: static;
}
.buttons-mobile {
  background: #beff00;
  border-radius: 286.39px;
  padding: 1px 8px 1px 8px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 17px;
  position: absolute;
  left: 220px;
  top: 725px;
  z-index: 1;
}
.buttons2-mobile {
  background: #beff00;
  border-radius: 286.39px;
  padding: 1px 8px 1px 8px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 17px;
  position: absolute;
  left: 288px;
  top: 725px;
  z-index: 1;
}
.tab-name-mobile {
  color: #000000;
  text-align: right;
  font: 500 11px/37.23px 'RoobertRegular', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 22px;
  z-index: 1;
  width: 60px;
}
.copy-mobile {
  position: absolute;
  left: 226px;
  top: 728px;
  overflow: visible;
  z-index: 1;
}
.copy2-mobile {
  position: absolute;
  left: 293px;
  top: 728px;
  overflow: visible;
  z-index: 1;
}
.theme-dark {
  background: #beff00;
  border-radius: 286.39px;
  padding: 5px 15px 5px 15px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% + 400px);
  top: 1310px;
  z-index: 1;
  cursor: pointer;
}
.theme-dark:hover {
  background: #7d5bbe;
}
.copy2 {
  position: absolute;
  left: calc(50% - 50px);
  top: 10px;
  overflow: visible;
}
.overlay {
  color: rgba(240, 240, 255, 0.7);
  text-align: right;
  font: 500 30px/37.23px 'RoobertRegular';
  position: absolute;
  left: calc(50% - 246px);
  top: calc(50% + 168px);
  display: flex;
  justify-content: flex-end;
  width: 590px;
}
.overlay-mobile {
  color: rgba(240, 240, 255, 0.7);
  text-align: right;
  font: 500 11px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 150px);
  top: calc(50% - -216px);
  display: flex;
  justify-content: flex-end;
  width: 160px;
}
.overlay-tooltip {
  z-index: 1;
  cursor: pointer;
}
.overlay-box {
  border-radius: 50%;
  border-style: solid;
  border-color: rgba(240, 240, 255, 0.7);
  border-width: 2px;
  width: 25px;
  height: 25px;
  position: absolute;
  left: calc(50% + 356px);
  top: 1322px;
}
.overlay-question {
  color: rgba(240, 240, 255, 0.7);
  text-align: left;
  font: 500 18px/37.23px 'arial';
  position: absolute;
  left: calc(50% + 363px);
  top: 1316px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 1;
  cursor: pointer;
}
.bg-closed {
  background: #111111;
  border-radius: 100px 100px 0px 0px;
  width: 100%;
  height: 379px;
  position: absolute;
  left: 0.46px;
  top: 1550px;
}
.bg-closed-mobile {
  background: #111111;
  border-radius: 30px 30px 0px 0px;
  width: 100% !important;
  height: 171px;
  position: absolute;
  left: 0px;
  bottom: 70px;
  visibility: hidden;

  @media only screen and (max-width: 500px) {
    visibility: visible;
  }
}
.bg-closed-mobile-box {
  background: #000000;
  width: 100% !important;
  height: 168px;
  position: absolute;
  left: 0px;
  bottom: -10px;
  visibility: hidden;

  @media only screen and (max-width: 500px) {
    visibility: visible;
  }
}
.closed {
  position: absolute;
  inset: 0;
}
.closed-title {
  color: #f0f0ff;
  text-align: left;
  font: 600 60px/64.44px 'RoobertRegular';
  position: absolute;
  left: calc(50% - 661px);
  top: 1637px;
  z-index: 10;
  cursor: pointer;

  @media only screen and (max-width: 500px) {
    font: 600 24px/64.44px 'RoobertRegular', sans-serif;
    position: absolute;
    left: calc(50% - 152px);
    top: 754px;
  }
}

.closed-title:hover {
  color: #beff00;
}
.closed-year-0 {
  background: #beff00;
  border-radius: 326.53px;
  padding: 13px 32.8px 13px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 661px);
  top: 1770px;
  z-index: 1;
  cursor: pointer;

  @media only screen and (max-width: 500px) {
    width: 55px;
    height: 25px;
    left: 42px;
    top: 806px;
  }
}
.closed-year-0:hover {
  background: #7d5bbe;
}
.closed-year-value {
  color: var(--black-ops, #000000);
  text-align: center;
  font: 500 34.21px/42.45px 'RoobertRegular';
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 500px) {
    font: 500 15px/42.45px 'RoobertRegular', sans-serif;
    top: -21px;
  }
}
.closed-year-1 {
  background: #beff00;
  border-radius: 326.53px;
  padding: 13px 32.8px 13px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 511px);
  top: 1770px;
  z-index: 1;
  cursor: pointer;

  @media only screen and (max-width: 500px) {
    width: 55px;
    height: 25px;
    left: 118px;
    top: 806px;
  }
}
.closed-year-1:hover {
  background: #7d5bbe;
}
.closed-year-2 {
  background: #beff00;
  border-radius: 326.53px;
  padding: 13px 32.8px 13px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 351px);
  top: 1770px;
  z-index: 1;
  cursor: pointer;

  @media only screen and (max-width: 500px) {
    width: 55px;
    height: 25px;
    left: 192px;
    top: 806px;
  }
}
.closed-year-2:hover {
  background: #7d5bbe;
}
.closed-year-3 {
  background: #beff00;
  border-radius: 326.53px;
  padding: 13px 32.8px 13px 32.8px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 191px);
  top: 2000px;
  z-index: 1;
  cursor: pointer;

  @media only screen and (max-width: 1080px) {
    width: 55px;
    height: 25px;
    left: 192px;
    top: 806px;
  }
}
.closed-year-3:hover {
  background: #7d5bbe;
}
.live-pill {
  width: 675.37px;
  height: 675.37px;
  position: absolute;
  right: -260px;
  top: -65px;
  transform-origin: 0 0;
  transform: rotate(3.639deg) scale(1, 1);

  @media only screen and (max-width: 500px) {
    width: 209.67px;
    height: 209.67px;
    position: absolute;
    left: 259.31px;
    top: 61px;
    transform-origin: 0 0;
    transform: rotate(3.639deg) scale(1, 1);
  }
}
.purple-bits {
  width: 1140px;
  height: 662.96px;
  position: absolute;
  left: -490px;
  top: 940px;
  transform-origin: 0 0;
  transform: rotate(4deg) scale(1, 1);

  @media only screen and (max-width: 500px) {
    visibility: hidden;
  }
}

.footer {
  position: absolute;
  inset: 0;
}

.twitch-glitch-white {
  z-index: 1;
  width: 64px;
  height: 75px;
  position: absolute;
  left: 98px;
  top: 2042px;

  @media only screen and (max-width: 500px) {
    width: 20px;
    height: 24px;
    left: 20px;
    top: unset;
    bottom: 104px !important;
  }
}

.terms-of-service {
  color: #ffffff;
  text-align: center;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 100px;
  top: 2190px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;

  @media only screen and (max-width: 500px) {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 37px;
    left: 20px;
    top: unset;
    bottom: 57px !important;
  }
}

.partner {
  color: #b3b3b3;
  text-align: right;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  top: 2190px;
  right: 98px;
  width: 984px;
  align-items: right;

  @media only screen and (max-width: 500px) {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    line-height: 14px;
    right: 20px;
    top: unset;
    bottom: 36px !important;
    min-width: 200px;
    max-width: 220px;
  }
}

.privacy-policy {
  color: #ffffff;
  text-align: center;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 330px;
  top: 2190px;
  display: flex;
  justify-content: left;
  text-decoration: none;

  @media only screen and (max-width: 500px) {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 37px;
    left: 20px;
    top: unset;
    bottom: 36px !important;
  }
}

.trade-mark {
  color: #ffffff;
  text-align: center;
  font: 500 20px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  top: 2154px;
  right: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 500px) {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 37px;
    right: 20px;
    top: unset;
    bottom: 57px !important;
  }
}

.open-campaigns {
  position: absolute;
  inset: 0;
}
.open-bg {
  background: #222222;
  border-radius: 100px 100px 0px 0px;
  width: 100%;
  height: 1417px;
  position: absolute;
  left: -1px;
  top: 305px;

  @media only screen and (max-width: 500px) {
    background: #222222;
    border-radius: 30px 30px 0px 0px;
    width: 100% !important;
    height: 829px;
    position: absolute;
    left: -1px;
    top: 156px;
  }
}
.open-label {
  color: #f0f0ff;
  text-align: left;
  font: 600 60px/64.44px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 661px);
  top: calc(50% - 724px);
  width: 564px;
  height: 38.98px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 10;
  cursor: pointer;

  @media only screen and (max-width: 500px) {
    font: 700 24px/37.23px 'RoobertRegular', sans-serif;
    left: calc(50% - 152px);
    top: 170px;
  }
}
.open-label:hover {
  color: #beff00;
}
.open-no-data {
  text-align: left;
  color: #f0f0ff;
  text-align: center;
  font-size: 60px;
  font-family: 'RoobertRegular', sans-serif;
  font-weight: 600;
  line-height: 64.438px;
  position: absolute;
  left: calc(50% - 350px);
  top: 874px;
  width: 875px;
  height: 51px;
  z-index: 10;

  @media only screen and (max-width: 500px) {
    text-align: left;
    font: 700 24px/90% 'RoobertRegular', sans-serif;
    position: absolute;
    left: 65px;
    top: 430px;
    width: 309px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

/* ----------------------- */
/*      Rewards Table      */
/* ----------------------- */

.bg-closed-open {
  background: #000000;
  border-radius: 100px 100px 0px 0px;
  width: 100%;
  height: 1234px;
  position: absolute;
  left: -1px;
  top: 558px;

  @media only screen and (max-width: 500px) {
    background: #111111;
    border-radius: 30px 30px 0px 0px;
    width: 100% !important;
    height: 1349px;
    top: 221px;
  }
}
.close-cam {
  position: absolute;
  inset: 0;
}
.closed-open-title {
  color: #f0f0ff;
  text-align: left;
  font: 600 60px/64.44px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 661px);
  top: 688px;
  width: 675px;
  height: 51px;
  z-index: 10;
  cursor: pointer;

  @media only screen and (max-width: 500px) {
    font: 700 24px/37.23px 'RoobertRegular', sans-serif;
    left: 43px;
    top: 231px;
  }
}
.closed-open-title:hover {
  color: #beff00;
}
.closed-select {
  width: 189px;
  height: 65px;
  position: static;
  z-index: 1;
}
.closed-select-year {
  color: #f0f0ff;
  text-align: left;
  position: absolute;
  left: calc(50% + 556px);
  top: 688px;
  z-index: 1;

  @media only screen and (max-width: 500px) {
    left: 265px;
    top: 226px;
    z-index: 100;
  }
}
.closed-select-year > div {
  font: 600 60px/64.44px 'RoobertRegular', sans-serif;
  width: 210px;

  @media only screen and (max-width: 500px) {
    width: 114px;
    font: 600 24px/37.23px 'RoobertRegular', sans-serif;
  }

  :first-child {
    height: 50px !important;
    :first-child {
      :first-child {
        :first-child {
          padding-top: 10px !important;
        }
      }
      :last-child {
        :first-child {
          top: -6px !important;
          font: 600 60px/64.44px 'RoobertRegular', sans-serif;

          @media only screen and (max-width: 500px) {
            top: -10px !important;
            left: -10px !important;
            font: 600 24px/37.23px 'RoobertRegular', sans-serif;
          }
        }
      }
    }
  }
}
.closed-no-data {
  text-align: left;
  color: #f0f0ff;
  text-align: center;
  font-size: 60px;
  font-family: 'RoobertRegular', sans-serif;
  font-weight: 600;
  line-height: 64.438px;
  position: absolute;
  left: calc(50% - 350px);
  top: 1214px;
  width: 675px;
  height: 51px;
  z-index: 10;

  @media only screen and (max-width: 500px) {
    text-align: left;
    font: 700 24px/90% 'RoobertRegular', sans-serif;
    position: absolute;
    left: 65px;
    top: 510px;
    width: 309px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
.closed-table-mobile {
  visibility: hidden;

  @media only screen and (max-width: 500px) {
    visibility: visible;
  }
}
.closed-table {
  position: absolute;
  inset: 0;

  @media only screen and (max-width: 500px) {
    visibility: hidden;
  }
}
.campaign-name {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 250px);
  top: 814px;
  width: 177px;
  height: 45px;
}
.level-completed {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 34px);
  top: 814px;
  width: 169px;
}
.score {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 110px);
  top: 814px;
  width: 169px;
}
.includes {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 308px);
  top: 814px;
  width: 169px;
}
.star-date {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 484px);
  top: 814px;
  width: 169px;
}
.end-date {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 21.87px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 668px);
  top: 814px;
  width: 169px;
}
.row-bg-0 {
  background: #111111;
  border-radius: 111.98px;
  width: 100%;
  max-width: 1522px;
  height: 105px;
  position: absolute;
  left: calc(50% - 690px);
  top: 869px;
}
.row-bg-1 {
  background: #111111;
  border-radius: 111.98px;
  width: 100%;
  max-width: 1522px;
  height: 105px;
  position: absolute;
  left: calc(50% - 690px);
  top: 999px;
}
.row-bg-2 {
  background: #111111;
  border-radius: 111.98px;
  width: 100%;
  max-width: 1522px;
  height: 105px;
  position: absolute;
  left: calc(50% - 690px);
  top: 1129px;
}
.row-bg-3 {
  background: #111111;
  border-radius: 111.98px;
  width: 100%;
  max-width: 1522px;
  height: 105px;
  position: absolute;
  left: calc(50% - 690px);
  top: 1259px;
}
.row-bg-4 {
  background: #111111;
  border-radius: 111.98px;
  width: 100%;
  max-width: 1522px;
  height: 105px;
  position: absolute;
  left: calc(50% - 690px);
  top: 1389px;
}
.row-bg-5 {
  background: #111111;
  border-radius: 111.98px;
  width: 100%;
  max-width: 1522px;
  height: 105px;
  position: absolute;
  left: calc(50% - 690px);
  top: 1519px;
}
.row-bg-6 {
  background: #111111;
  border-radius: 111.98px;
  width: 100%;
  max-width: 1522px;
  height: 105px;
  position: absolute;
  left: calc(50% - 690px);
  top: 1649px;
}
.row-rewards-bg-0 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 672px);
  top: 885px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-rewards-bg-0:hover {
  background: #7d5bbe;
}
.row-rewards-bg-1 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 672px);
  top: 1016px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-rewards-bg-1:hover {
  background: #7d5bbe;
}
.row-rewards-bg-2 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 672px);
  top: 1146px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-rewards-bg-2:hover {
  background: #7d5bbe;
}
.row-rewards-bg-3 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 672px);
  top: 1276px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-rewards-bg-3:hover {
  background: #7d5bbe;
}
.row-rewards-bg-4 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 672px);
  top: 1406px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-rewards-bg-4:hover {
  background: #7d5bbe;
}
.row-rewards-bg-5 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 672px);
  top: 1536px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-rewards-bg-5:hover {
  background: #7d5bbe;
}
.row-rewards-bg-6 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 672px);
  top: 1666px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-rewards-bg-6:hover {
  background: #7d5bbe;
}
.row-details-bg-0 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 466px);
  top: 885px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-details-bg-0:hover {
  background: #7d5bbe;
}
.row-details-bg-1 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 466px);
  top: 1016px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-details-bg-1:hover {
  background: #7d5bbe;
}
.row-details-bg-2 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 466px);
  top: 1146px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-details-bg-2:hover {
  background: #7d5bbe;
}
.row-details-bg-3 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 466px);
  top: 1276px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-details-bg-3:hover {
  background: #7d5bbe;
}
.row-details-bg-4 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 466px);
  top: 1406px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-details-bg-4:hover {
  background: #7d5bbe;
}
.row-details-bg-5 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 466px);
  top: 1536px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-details-bg-5:hover {
  background: #7d5bbe;
}
.row-details-bg-6 {
  background: var(--color-brand-accent-lime, #beff00);
  border-radius: 326.53px;
  padding: 14px 15px 14px 15px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: calc(50% - 466px);
  top: 1666px;
  width: 190px;
  z-index: 1;
  cursor: pointer;
}
.row-details-bg-6:hover {
  background: #7d5bbe;
}
.row-button-label {
  color: var(--black, #000000);
  text-align: center;
  font: 600 24.21px/42.45px 'RoobertRegular', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 240px;
}
.row-name-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 248px);
  top: 905px;
  width: 185px;
  z-index: 1;
}
.row-name-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 248px);
  top: 1035px;
  width: 185px;
  z-index: 1;
}
.row-name-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 248px);
  top: 1165px;
  width: 185px;
  z-index: 1;
}
.row-name-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 248px);
  top: 1295px;
  width: 185px;
  z-index: 1;
}
.row-name-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 248px);
  top: 1425px;
  width: 185px;
  z-index: 1;
}
.row-name-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 248px);
  top: 1555px;
  width: 185px;
  z-index: 1;
}
.row-name-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 248px);
  top: 1685px;
  width: 185px;
  z-index: 1;
}
.row-level-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 30px);
  top: 905px;
  width: 154px;
}
.row-level-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 30px);
  top: 1035px;
  width: 185px;
}
.row-level-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 30px);
  top: 1165px;
  width: 185px;
}
.row-level-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 30px);
  top: 1295px;
  width: 185px;
}
.row-level-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 30px);
  top: 1425px;
  width: 185px;
}
.row-level-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 30px);
  top: 1555px;
  width: 185px;
}
.row-level-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 30px);
  top: 1685px;
  width: 185px;
}
.row-score-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 110px);
  top: 905px;
  width: 185px;
}
.row-score-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 110px);
  top: 1035px;
  width: 169px;
  height: 39px;
}
.row-score-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 110px);
  top: 1161px;
  width: 150px;
  height: 53px;
}
.row-score-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 110px);
  top: 1295px;
  width: 185px;
  height: 53px;
}
.row-score-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 110px);
  top: 1425px;
  width: 192px;
  height: 53px;
}
.row-score-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 110px);
  top: 1556px;
  width: 176px;
  height: 53px;
}
.row-score-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 110px);
  top: 1685px;
  width: 192px;
  height: 53px;
}
.row-includes-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 310px);
  top: 907px;
  width: 150px;
  height: 53px;
}
.row-includes-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 310px);
  top: 1035px;
  width: 150px;
  height: 53px;
}
.row-includes-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 310px);
  top: 1165px;
  width: 136px;
}
.row-includes-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 310px);
  top: 1295px;
  width: 136px;
}
.row-includes-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 310px);
  top: 1425px;
  width: 136px;
}
.row-includes-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 310px);
  top: 1555px;
  width: 136px;
}
.row-includes-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 310px);
  top: 1685px;
  width: 165px;
}
.row-start-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 485px);
  top: 906px;
  width: 150px;
  height: 53px;
}
.row-start-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 485px);
  top: 1035px;
  width: 136px;
  height: 27px;
}
.row-start-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 485px);
  top: 1161px;
  width: 150px;
  height: 53px;
}
.row-start-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 485px);
  top: 1295px;
  width: 150px;
  height: 53px;
}
.row-start-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 485px);
  top: 1425px;
  width: 150px;
  height: 53px;
}
.row-start-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 485px);
  top: 1556px;
  width: 150px;
  height: 53px;
}
.row-start-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 485px);
  top: 1685px;
  width: 150px;
  height: 53px;
}
.row-end-0 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 670px);
  top: 906px;
  width: 150px;
  height: 53px;
}
.row-end-1 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 670px);
  top: 1035px;
  width: 150px;
  height: 53px;
}
.row-end-2 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 670px);
  top: 1162px;
  width: 150px;
  height: 53px;
}
.row-end-3 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 670px);
  top: 1296px;
  width: 150px;
  height: 53px;
}
.row-end-4 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 670px);
  top: 1426px;
  width: 150px;
  height: 53px;
}
.row-end-5 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 670px);
  top: 1557px;
  width: 150px;
  height: 53px;
}
.row-end-6 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 26.24px/32.57px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% + 670px);
  top: 1686px;
  width: 150px;
  height: 53px;
}

.row-mobile {
  box-sizing: border-box;
  position: absolute;
  inset: 0;
}
.row-bg-mobile {
  background: #000000;
  border-radius: 10px;
  width: 306px;
  height: 217px;
  position: absolute;
  left: 43px;
  top: 285px;
}
.buttons {
  background: #beff00;
  border-radius: 326.53px;
  padding: 1px 10px 1px 10px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: center;
  height: 25px;
  position: absolute;
  left: 50px;
  top: 305px;
  width: 144px;
  z-index: 10;
}
.tab-name {
  color: var(--black, #000000);
  text-align: center;
  font: 500 12px/42.45px 'RoobertRegular', sans-serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  @media only screen and (max-width: 500px) {
    visibility: visible;
  }
}
.buttons2 {
  background: #beff00;
  border-radius: 326.53px;
  padding: 1px 10px 1px 10px;
  display: flex;
  flex-direction: column;
  gap: 42.45px;
  align-items: center;
  justify-content: center;
  height: 25px;
  position: absolute;
  left: 200px;
  top: 305px;
  width: 144px;
  z-index: 100;
}
.group-65 {
  width: 268px;
  height: 143px;
  position: static;

  visibility: hidden;
  @media only screen and (max-width: 500px) {
    visibility: visible;
  }
}
.start-date-mobile {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 444px;
  width: 118px;

  visibility: hidden;
  @media only screen and (max-width: 500px) {
    visibility: visible;
  }
}
.score-mobile {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 391px;
  width: 48px;
  visibility: hidden;
  @media only screen and (max-width: 500px) {
    visibility: visible;
  }
}
.includes-mobile {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 223px;
  top: 391px;
  width: 82px;
  visibility: hidden;
  @media only screen and (max-width: 500px) {
    visibility: visible;
  }
}
.campaign-name-mobile {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 342px;
  width: 120px;
  height: 21px;
  visibility: hidden;
  @media only screen and (max-width: 500px) {
    visibility: visible;
  }
}
.prod-8929 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 361px;
  width: 150px;
  height: 21px;
}
._2022-11-30 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 463px;
  width: 90px;
  height: 21px;
  visibility: hidden;
  @media only screen and (max-width: 500px) {
    visibility: visible;
  }
}
._100-000-000 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 62px;
  top: 411px;
  width: 90px;
  height: 21px;
  visibility: hidden;
  @media only screen and (max-width: 500px) {
    visibility: visible;
  }
}
.level-completed-mobile {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 223px;
  top: 342px;
  width: 157px;
  height: 22px;
  visibility: hidden;
  @media only screen and (max-width: 500px) {
    visibility: visible;
  }
}
._12 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 223px;
  top: 361px;
  width: 78px;
  height: 22px;
  visibility: hidden;
  @media only screen and (max-width: 500px) {
    visibility: visible;
  }
}
._2022-12-10 {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 223px;
  top: 463px;
  width: 78px;
  height: 22px;
  visibility: hidden;
  @media only screen and (max-width: 500px) {
    visibility: visible;
  }
}
.subs-bits-mobile {
  color: var(--color-brand-muted-ice, #f0f0ff);
  text-align: left;
  font: 600 14px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 223px;
  top: 411px;
  width: 78px;
  height: 22px;
  visibility: hidden;
  @media only screen and (max-width: 500px) {
    visibility: visible;
  }
}
.end-date-mobile {
  color: var(--color-brand-accent-lime, #beff00);
  text-align: left;
  font: 600 11px/23.93px 'RoobertRegular', sans-serif;
  position: absolute;
  left: 223px;
  top: 444px;
  width: 78px;

  visibility: hidden;
  @media only screen and (max-width: 500px) {
    visibility: visible;
  }
}

._2-current-score {
  width: 1332px;
  height: 75px;
  position: static;
}
.rectangle-6 {
  background: #fa1ed2;
  border-radius: 101px;
  width: 1332px;
  height: 75px;
  position: absolute;
  top: 544px;
  left: calc(50% - 660px);

  @media only screen and (max-width: 500px) {
    width: 310px;
    height: 40px;
    top: 265px;
    left: calc(50% - 154px);
  }
}
.starting-soon {
  color: #f0f0ff;
  text-align: center;
  font: 600 50px/37.23px 'RoobertRegular', sans-serif;
  position: absolute;
  left: calc(50% - 580px);
  top: 561px;
  width: 1260.67px;
  height: 49px;

  @media only screen and (max-width: 500px) {
    color: #f0f0ff;
    text-align: left;
    font: 700 24px/37.23px 'RoobertRegular', sans-serif;
    position: absolute;
    left: calc(50% - 62px);
    top: calc(50% - 242px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.link1-button {
  width: 210px;
  height: 48px;
  background: #beff00;
  border-radius: 286.39px;
  padding: 5px 15px 5px 15px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% - 650px);
  top: 1310px;
  z-index: 1;
  cursor: pointer;

  div {
    left: -14px !important;
  }
}
.link1-button:hover {
  background: #7d5bbe;
}

.link2-button {
  width: 210px;
  height: 48px;
  background: #beff00;
  border-radius: 286.39px;
  padding: 5px 15px 5px 15px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% - 420px);
  top: 1310px;
  z-index: 1;
  cursor: pointer;

  div {
    left: -14px !important;
  }
}
.link2-button:hover {
  background: #7d5bbe;
}

.link3-button {
  width: 210px;
  height: 48px;
  background: #beff00;
  border-radius: 286.39px;
  padding: 5px 15px 5px 15px;
  display: flex;
  flex-direction: column;
  gap: 37.23px;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: calc(50% - 190px);
  top: 1310px;
  z-index: 1;
  cursor: pointer;

  div {
    left: -14px !important;
  }
}
.link3-button:hover {
  background: #7d5bbe;
}

.link-text {
  color: var(--black, #000000);
  font: 600 20px/18px 'RoobertRegular';

  @media only screen and (max-width: 500px) {
    z-index: 1;
  }
}

.link-label {
  color: #000000;
  font: 500 30px/37.23px 'RoobertRegular';
  align-items: center;
}
</style>
